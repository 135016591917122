import React from 'react';
import { 
  Typography, 
  Box, 
  Link, 
  Stack,
  IconButton,
  Container,
  Button,
  Card,
  CardContent,
  CardActions,
  Chip
} from '@mui/material';
import {
  LinkedIn as LinkedInIcon,
  GitHub as GitHubIcon,
  Email as EmailIcon,
  FlightTakeoff as FlightTakeoffIcon,
  Apps as AppsIcon
} from '@mui/icons-material';
import XIcon from '../components/XIcon';
import { useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate();

  return (
    <Box sx={{ 
      minHeight: 'calc(100vh - 64px)',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      overflow: 'hidden',
      py: 8
    }}>
      {/* Background gradient animation */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'radial-gradient(circle at 50% 50%, rgba(144, 202, 249, 0.1) 0%, rgba(0, 0, 0, 0) 50%)',
          animation: 'pulse 8s ease-in-out infinite',
          '@keyframes pulse': {
            '0%': { opacity: 0.5 },
            '50%': { opacity: 1 },
            '100%': { opacity: 0.5 },
          },
          zIndex: 0
        }}
      />

      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
        <Box sx={{ 
          textAlign: 'center', 
          mb: 8
        }}>
          <Typography 
            variant="h1" 
            component="h1" 
            gutterBottom
            sx={{ 
              fontSize: { xs: '3rem', md: '4.5rem' },
              fontWeight: 700,
              background: 'linear-gradient(45deg, #90caf9 30%, #ce93d8 90%)',
              backgroundClip: 'text',
              textFillColor: 'transparent',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              mb: 3
            }}
          >
            tongy.io
          </Typography>
          <Typography 
            variant="h4" 
            color="text.secondary" 
            gutterBottom
            sx={{ 
              fontWeight: 400,
              mb: 4
            }}
          >
            Accessible innovation
          </Typography>

          {/* Social Links */}
          <Stack 
            direction="row" 
            spacing={3} 
            justifyContent="center" 
            sx={{ mb: 8 }}
          >
            <IconButton 
              component={Link}
              href="https://x.com/tongy24"
              target="_blank"
              sx={{
                color: 'text.secondary',
                transition: 'all 0.2s',
                '&:hover': {
                  color: 'primary.main',
                  transform: 'translateY(-2px)'
                }
              }}
            >
              <XIcon />
            </IconButton>
            <IconButton 
              component={Link}
              href="https://www.linkedin.com/in/hjtong/"
              target="_blank"
              sx={{
                color: 'text.secondary',
                transition: 'all 0.2s',
                '&:hover': {
                  color: 'primary.main',
                  transform: 'translateY(-2px)'
                }
              }}
            >
              <LinkedInIcon />
            </IconButton>
            <IconButton 
              component={Link}
              href="https://github.com/tongy24"
              target="_blank"
              sx={{
                color: 'text.secondary',
                transition: 'all 0.2s',
                '&:hover': {
                  color: 'primary.main',
                  transform: 'translateY(-2px)'
                }
              }}
            >
              <GitHubIcon />
            </IconButton>
            <IconButton 
              component={Link}
              href="mailto:harry@tongy.io"
              sx={{
                color: 'text.secondary',
                transition: 'all 0.2s',
                '&:hover': {
                  color: 'primary.main',
                  transform: 'translateY(-2px)'
                }
              }}
            >
              <EmailIcon />
            </IconButton>
          </Stack>

          {/* Featured Apps Section */}
          <Box sx={{ mt: 4 }}>
            <Typography 
              variant="h5" 
              sx={{ 
                mb: 4,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2
              }}
            >
              Featured Apps
            </Typography>

            <Card 
              sx={{ 
                maxWidth: 600,
                mx: 'auto',
                background: 'linear-gradient(145deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0.02) 100%)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255, 255, 255, 0.1)',
              }}
            >
              <CardContent sx={{ textAlign: 'left', p: 4 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Typography variant="h4" component="h2" sx={{ mr: 2 }}>
                    Holidai
                  </Typography>
                  <Chip 
                    label="Beta" 
                    size="small" 
                    sx={{ 
                      backgroundColor: 'primary.main',
                      color: 'primary.contrastText'
                    }} 
                  />
                </Box>
                <Typography 
                  variant="body1" 
                  color="text.secondary" 
                  sx={{ mb: 3 }}
                >
                  Plan your next trip with AI. Get personalized travel recommendations and create your perfect itinerary using advanced AI technology.
                </Typography>
              </CardContent>
              <CardActions sx={{ p: 4, pt: 0 }}>
                <Button
                  variant="contained"
                  size="large"
                  startIcon={<FlightTakeoffIcon />}
                  onClick={() => navigate('/holiday-planner')}
                  sx={{
                    py: 1.5,
                    px: 3,
                    background: 'linear-gradient(45deg, #90caf9 30%, #ce93d8 90%)',
                    boxShadow: '0 3px 15px rgba(144, 202, 249, 0.3)',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #82bef7 30%, #c77ed0 90%)',
                      boxShadow: '0 5px 20px rgba(144, 202, 249, 0.5)',
                    }
                  }}
                >
                  Launch App
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Home; 