import { CognitoUserPool, CognitoUserAttribute, CognitoUser } from 'amazon-cognito-identity-js';
import axios from 'axios';
import secretsService from './secretsService';
import { AuthenticationDetails } from 'amazon-cognito-identity-js';

// Get environment variables from runtime config
const getEnvVar = (key) => {
    return window._env_?.[key] || process.env[key];
};

// Environment-specific configuration
const getConfig = async () => {
    const env = process.env.NODE_ENV || 'development';

    try {
        // Get Cognito configuration from Secrets Manager
        const cognitoConfig = await secretsService.getCognitoConfig();
        
        // API URL configuration - use full URL in production
        const apiUrl = env === 'production' 
            ? (getEnvVar('REACT_APP_API_URL') || 'https://api.tongy.io')  // Use full URL in production
            : (getEnvVar('REACT_APP_API_URL') || 'http://localhost:5001');  // Development API URL

        return { baseConfig: cognitoConfig, apiUrl };
    } catch (error) {
        console.error('[AuthService] Error loading configuration');
        throw new Error('Failed to load configuration');
    }
};

// Initialize services
let userPool;
let api;

const initializeServices = async () => {
    try {
        const env = process.env.NODE_ENV || 'development';
        const config = await secretsService.getCognitoConfig();

        // Initialize Cognito User Pool
        userPool = new CognitoUserPool(config);

        // Initialize Axios instance with base URL
        api = axios.create({
            baseURL: env === 'production' ? (getEnvVar('REACT_APP_API_URL') || 'https://api.tongy.io') : (getEnvVar('REACT_APP_API_URL') || 'http://localhost:5001'),
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return { userPool, api };
    } catch (error) {
        console.error('[AuthService] Error initializing services');
        throw error;
    }
};

class AuthService {
    constructor() {
        this.initialized = false;
        console.log('[AuthService] AuthService instance created');
    }

    async ensureInitialized() {
        if (!this.initialized) {
            console.log('[AuthService] Ensuring services are initialized...');
            const services = await initializeServices();
            this.userPool = services.userPool;
            this.api = services.api;
            this.initialized = true;
            console.log('[AuthService] Services initialized successfully');
        }
    }

    // Get current user session
    async getCurrentUser() {
        await this.ensureInitialized();
        return new Promise((resolve, reject) => {
            const currentUser = this.userPool.getCurrentUser();
            if (!currentUser) {
                console.log('[AuthService] No current user found');
                resolve(null);
                return;
            }

            currentUser.getSession((err, session) => {
                if (err) {
                    console.log('[AuthService] Session error:', err);
                    if (err.code === 'UserNotFoundException' || err.message.includes('User does not exist')) {
                        resolve(null);
                    } else {
                        reject(new Error(`Failed to get session: ${err.message}`));
                    }
                } else if (!session.isValid()) {
                    console.log('[AuthService] Invalid session, clearing local storage');
                    currentUser.signOut();
                    resolve(null);
                } else {
                    console.log('[AuthService] Current user session retrieved successfully');
                    resolve({
                        username: currentUser.username,
                        session: session
                    });
                }
            });
        });
    }

    // Login user
    async login(email, password) {
        try {
            console.log('[AuthService] Attempting login for:', email);
            const { userPool } = await initializeServices();
            
            return new Promise((resolve, reject) => {
                const userData = {
                    Username: email,
                    Pool: userPool
                };
                
                const cognitoUser = new CognitoUser(userData);
                
                const authDetails = new AuthenticationDetails({
                    Username: email,
                    Password: password
                });
                
                cognitoUser.authenticateUser(authDetails, {
                    onSuccess: async (session) => {
                        try {
                            console.log('[AuthService] Login successful');
                            // Store the session
                            userPool.getCurrentUser().setSignInUserSession(session);
                            
                            // Verify with backend with retry mechanism
                            let retryCount = 0;
                            const maxRetries = 3;
                            const retryDelay = 2000; // 2 seconds

                            const verifyWithBackend = async () => {
                                try {
                                    console.log('[AuthService] Sending verification request to backend');
                                    const idToken = session.getIdToken().getJwtToken();
                                    console.log('[AuthService] Using ID token for verification');
                                    const response = await this.api.post('/api/auth/verify-email', {}, {
                                        headers: {
                                            Authorization: `Bearer ${idToken}`
                                        }
                                    });

                                    console.log('[AuthService] Backend response:', response.data);

                                    // Check if response and message exist
                                    if (!response || !response.data || !response.data.message) {
                                        console.error('[AuthService] Invalid response from backend:', response);
                                        throw new Error('Invalid response from backend');
                                    }

                                    const message = response.data.message;
                                    
                                    // Handle different backend responses
                                    if (message.includes('Email already verified')) {
                                        resolve(session);
                                    } else if (message.includes('Email verified successfully')) {
                                        resolve(session);
                                    } else if (message.includes('pending admin approval')) {
                                        reject(new Error('Your account is pending admin approval. Please wait for approval.'));
                                    } else {
                                        reject(new Error('Unexpected response from backend: ' + message));
                                    }
                                } catch (error) {
                                    console.error('[AuthService] Backend verification error:', error);
                                    if (error.response) {
                                        switch (error.response.status) {
                                            case 401:
                                                reject(new Error('Invalid credentials'));
                                                break;
                                            case 403:
                                                reject(new Error('Your account is pending admin approval'));
                                                break;
                                            case 404:
                                                reject(new Error('User not found'));
                                                break;
                                            default:
                                                reject(new Error('Failed to verify with backend'));
                                        }
                                    } else if (retryCount < maxRetries) {
                                        retryCount++;
                                        setTimeout(verifyWithBackend, retryDelay);
                                    } else {
                                        reject(new Error('Failed to verify with backend after multiple attempts'));
                                    }
                                }
                            };

                            verifyWithBackend();
                        } catch (error) {
                            console.error('[AuthService] Error during login process:', error);
                            reject(error);
                        }
                    },
                    onFailure: (err) => {
                        console.error('[AuthService] Cognito authentication failure:', err);
                        let errorMessage = 'Authentication failed';
                        
                        if (err.code === 'UserNotFoundException') {
                            errorMessage = 'User not found';
                        } else if (err.code === 'NotAuthorizedException') {
                            errorMessage = 'Incorrect username or password';
                        } else if (err.code === 'UserNotConfirmedException') {
                            errorMessage = 'Please verify your email address';
                        } else if (err.code === 'PasswordResetRequiredException') {
                            errorMessage = 'Password reset required';
                        }
                        
                        reject(new Error(errorMessage));
                    }
                });
            });
        } catch (error) {
            console.error('[AuthService] Login error:', error);
            throw error;
        }
    }

    // Register new user
    async register(name, email, password) {
        await this.ensureInitialized();
        return new Promise((resolve, reject) => {
            console.log('[AuthService] Attempting registration for:', email);
            const attributeList = [
                new CognitoUserAttribute({
                    Name: 'name',
                    Value: name
                }),
                new CognitoUserAttribute({
                    Name: 'email',
                    Value: email
                })
            ];

            this.userPool.signUp(
                email,
                password,
                attributeList,
                null, // No validation data needed
                (err, result) => {
                    if (err) {
                        console.error('[AuthService] Cognito signup error:', err);
                        let errorMessage = 'Failed to sign up';
                        
                        // Handle specific Cognito error cases
                        switch (err.name) {
                            case 'InvalidPasswordException':
                                errorMessage = 'Password does not meet requirements. ' + err.message;
                                break;
                            case 'UsernameExistsException':
                                errorMessage = 'This email address is already registered';
                                break;
                            case 'InvalidParameterException':
                                if (err.message.includes('password')) {
                                    errorMessage = 'Password must be at least 8 characters long and contain uppercase, lowercase, numbers, and special characters';
                                } else if (err.message.includes('email')) {
                                    errorMessage = 'Please enter a valid email address';
                                } else {
                                    errorMessage = err.message;
                                }
                                break;
                            case 'CodeDeliveryFailureException':
                                errorMessage = 'Failed to send verification code. Please try again';
                                break;
                            default:
                                errorMessage = err.message || 'An unexpected error occurred during signup';
                        }
                        reject(new Error(errorMessage));
                    } else {
                        console.log('[AuthService] Signup successful:', result);
                        resolve(result);
                    }
                }
            );
        });
    }

    // Confirm registration
    async confirmRegistration(username, code) {
        try {
            console.log('[AuthService] Attempting to confirm registration for:', username);
            const { userPool } = await initializeServices();
            
            return new Promise((resolve, reject) => {
                const userData = {
                    Username: username,
                    Pool: userPool
                };
                
                const cognitoUser = new CognitoUser(userData);
                
                cognitoUser.confirmRegistration(code, true, async (err, result) => {
                    if (err) {
                        console.error('[AuthService] Error confirming registration:', err);
                        reject(err);
                        return;
                    }
                    
                    console.log('[AuthService] Cognito registration confirmed');
                    
                    try {
                        // Get the session after confirmation
                        cognitoUser.getSession((err, session) => {
                            if (err) {
                                console.error('[AuthService] Error getting session:', err);
                                reject(err);
                                return;
                            }
                            
                            // Call backend to verify email
                            this.api.post('/api/auth/verify-email', {}, {
                                headers: {
                                    Authorization: `Bearer ${session.getIdToken().getJwtToken()}`
                                }
                            }).then(response => {
                                console.log('[AuthService] Backend verification successful:', response.data);
                                resolve(result);
                            }).catch(error => {
                                console.error('[AuthService] Backend verification failed:', error);
                                reject(error);
                            });
                        });
                    } catch (error) {
                        console.error('[AuthService] Error during backend verification:', error);
                        reject(error);
                    }
                });
            });
        } catch (error) {
            console.error('[AuthService] Error during verification process:', error);
            throw error;
        }
    }

    // Logout user
    async logout() {
        await this.ensureInitialized();
        console.log('[AuthService] Attempting logout');
        const currentUser = this.userPool.getCurrentUser();
        if (currentUser) {
            currentUser.signOut();
            console.log('[AuthService] Logout successful');
        } else {
            console.log('[AuthService] No user to logout');
        }
    }

    // Verify email
    async verifyEmail(token) {
        await this.ensureInitialized();
        try {
            console.log('[AuthService] Attempting email verification');
            const response = await this.api.post('/api/auth/verify-email', {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log('[AuthService] Email verification successful');
            return response.data.message;
        } catch (err) {
            console.error('[AuthService] Email verification error:', err);
            throw new Error(err.response?.data?.error || 'Failed to verify email');
        }
    }

    // Forgot password
    async forgotPassword(email) {
        try {
            console.log('[AuthService] Initiating forgot password for:', email);
            const { userPool } = await initializeServices();
            
            return new Promise((resolve, reject) => {
                const userData = {
                    Username: email,
                    Pool: userPool
                };
                
                const cognitoUser = new CognitoUser(userData);
                
                cognitoUser.forgotPassword({
                    onSuccess: () => {
                        console.log('[AuthService] Forgot password code sent successfully');
                        resolve();
                    },
                    onFailure: (err) => {
                        console.error('[AuthService] Forgot password error:', err);
                        let errorMessage = 'Failed to initiate password reset';
                        
                        if (err.code === 'UserNotFoundException') {
                            errorMessage = 'User not found';
                        } else if (err.code === 'InvalidParameterException') {
                            errorMessage = 'Invalid email format';
                        }
                        
                        reject(new Error(errorMessage));
                    }
                });
            });
        } catch (error) {
            console.error('[AuthService] Forgot password error:', error);
            throw error;
        }
    }

    // Confirm forgot password
    async confirmForgotPassword(email, code, newPassword) {
        try {
            console.log('[AuthService] Confirming password reset for:', email);
            const { userPool } = await initializeServices();
            
            return new Promise((resolve, reject) => {
                const userData = {
                    Username: email,
                    Pool: userPool
                };
                
                const cognitoUser = new CognitoUser(userData);
                
                cognitoUser.confirmForgotPassword(code, newPassword, {
                    onSuccess: () => {
                        console.log('[AuthService] Password reset successful');
                        resolve();
                    },
                    onFailure: (err) => {
                        console.error('[AuthService] Password reset error:', err);
                        let errorMessage = 'Failed to reset password';
                        
                        if (err.code === 'CodeMismatchException') {
                            errorMessage = 'Invalid verification code';
                        } else if (err.code === 'ExpiredCodeException') {
                            errorMessage = 'Verification code has expired';
                        } else if (err.code === 'InvalidPasswordException') {
                            errorMessage = 'Password does not meet requirements';
                        }
                        
                        reject(new Error(errorMessage));
                    }
                });
            });
        } catch (error) {
            console.error('[AuthService] Confirm forgot password error:', error);
            throw error;
        }
    }
}

// Create and export a singleton instance
const authService = new AuthService();
export default authService; 