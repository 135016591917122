import { CognitoUserPool } from 'amazon-cognito-identity-js';

// Cognito configuration
export const cognitoConfig = {
    UserPoolId: 'us-east-1_FnbbMKPce',
    ClientId: '21v85419seoanedrf3grht51k1'
};

// Validate configuration
Object.entries(cognitoConfig).forEach(([key, value]) => {
    if (!value) {
        throw new Error(`Missing required Cognito configuration: ${key}`);
    }
});

// Initialize Cognito User Pool
const userPool = new CognitoUserPool(cognitoConfig);

export default userPool; 